import React from 'react'
import girl from '../utils/images/Purple and Pink Playful Online Course Facebook Post.png'
import { Link } from 'react-router-dom'
export default function CallingAbout() {
  return (
    <>
      <div className='flex flex-col md:flex-row justify-around items-center mx-2'>

<div className='left md:w-1/3 w-full my-6'>
  <div className='img flex justify-center'>
    <img className='h-auto md:w-[600px] w-full rounded-xl' src={girl} alt="" />
  </div>
</div>

<div className='right md:w-1/3 w-full text-center md:text-left'>
  <div className='text mx-4'>
    <div className='heading'>
      <h1 className='text-2xl md:text-4xl font-medium my-4'>Learn Anything From Anywhere With <span className='text-cyan-600 font-extrabold'>The Study Wave</span></h1>
    </div>
    <div className='sub-heading my-2'>
      <p className='text-gray-500'>We Provide a Convenient and accessible platform for individuals to access educational content and courses from the comfort of their homes.</p>
    </div>

    <div>
      <Link to="/about">
        <button type="button" class="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 mt-4">Learn About Us</button>
      </Link>
    </div>
  </div>
</div>

</div>

    </>
  )
}
