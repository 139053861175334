import React from 'react'
import { useState } from 'react'
import img from '../utils/images/emailmodalImg.avif'
import { useNavigate } from 'react-router-dom'
import SuccessModal from './SuccessModal'
export default function EmailModal() {
const [input,setInput] = useState({name:"",email:"",number:""})
const navigate = useNavigate()
const [success,setSucces] = useState(false);
const [loading,setLoading] = useState(false);
const handleChange = (e)=>{
    setInput({...input,[e.target.name]:e.target.value})
}
//     const handleSubmit = async (e) => {

// e.preventDefault();
//  setLoading(true);

  

//     } 
    
    return (
    <>
<div className='flex justify-center items-center'>
{/* {success && <SuccessModal success={success} setSuccess={setSucces} />} */}
</div>
    
            <div>
                <div className='heading'>
                    <div className='flex flex-col justify-center items-center text-center'>
                        <h1 className='font-bold text-4xl head1 '>Book A Class With Us..</h1>
                        <p className='font-light font-serif text-xl my-4  mx-12 text-gray-600'>Embark on a journey of discovery and growth with us. Booking a class is your first step towards unlocking a world of knowledge, tailored learning experiences, and interactive sessions designed to elevate your understanding and skills. Whether you're a beginner eager to learn the basics or an advanced learner looking to deepen your expertise, our diverse range of classes offers something for everyone. Join us and transform your potential into achievement.</p>
                    </div>
                </div>

                <div className='email flex justify-center items-center md:flex-row flex-col '>
<div className='img  flex-1'>
<img className=' rounded-3xl ' src={img} alt="" />
</div> 



{success ?
    <div className='form my-[-50px] flex-1'> <SuccessModal success={success} setSuccess={setSucces} />
    </div> :(
    <div className='form my-[-50px] flex-1'>
    {/* {/* <section className="bg-gray-50 dark:bg-white"> */}
        <div className="flex flex-col items-center justify-center px-4 py-4 mx-auto md:h-screen lg:py-0"> 
    
            <div className="w-full my-12 bg-white rounded-lg   shadow-slate-600 shadow-lg md:mt-0 sm:max-w-md xl:p-0 dark:bg-white ">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-blue-800">
                        Join Us Now
                    </h1>
                    <form
  className="space-y-4 md:space-y-6 p-4 md:p-8 bg-white shadow-md rounded-lg max-w-lg mx-auto my-10"
  action="https://formsubmit.co/1e072aadd1ae79270fcc02a1063f2e7b"
  method="POST"
  onSubmit={(e) => {
   
    const submitButton = e.target.querySelector('button[type="submit"]');
    submitButton.disabled = true;
    submitButton.style.opacity = "0.5";
    submitButton.textContent = "Sending...";

    // After 15 seconds, revert the button to its original state
    setTimeout(() => {
      submitButton.disabled = false;
      submitButton.style.opacity = "1";
      submitButton.textContent = "Send";
    }, 15000);
  }}
>
  <div className="flex flex-col gap-4">
    <input
      className="p-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 transition ease-in-out"
      type="text"
      name="name"
      placeholder="Your Name"
      required
    />
    <input
      className="p-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 transition ease-in-out"
      type="email"
      name="email"
      placeholder="Your Email"
      required
    />
    <input
      className="p-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 transition ease-in-out"
      type="text"
      name="number"
      placeholder="Your Phone Number"
      required
    />
    <button
    // onClick={handleSubmit}
      type="submit"
      className="p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition ease-in-out "
    >
      Send
    </button>
  </div>

  <p className="text-center font-serif mt-4">
    After registering with us, we will get in touch with you shortly.
  </p>
</form>
                </div>
            </div>
         </div>
    {/* </section> */} 
    </div>
) }

                    </div>

                </div>
            </>
            )
}



