import React from 'react'
import { Link } from 'react-router-dom'
import { FaCheck } from "react-icons/fa";

export default function SuccessModal({success,setSuccess}) {
    const handleClick =  ()=>{
setSuccess(false)
    }
  return (
    <>
      


<div id="popup-modal" tabindex="-1" className="    top-50 right-0 left-50 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div className="relative p-4 w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button onClick={handleClick} type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span className="sr-only">Close modal </span>
            </button>
            <div className="p-4 md:p-5 text-center">
                <div className='flex justify-center items-center my-2 text-4xl text-green-600'>
                <FaCheck  />
                </div>
           
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Email Sent Successfuly <br />We will get in touch with shortly <br />THANK YOU dcwcwec</h3>
                <Link to="/">
                <button data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                    Go To Home
                </button>
                </Link>
                
            </div>
        </div>
    </div>
</div>

    </>
  )
}
