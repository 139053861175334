import React from 'react';
import { Link } from 'react-router-dom';
import { FaPhone, FaEnvelope, FaLinkedin, FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa';

function ContactUs() {
  return (
    <>
    
    <div style={styles.container}>
      <h1 style={styles.heading}>Contact Us</h1>
      <p className='text-lg font-bold'>If you have any questions or inquiries, please feel free to reach out to us using the contact information provided below.</p>
      
      <div style={styles.contactInfo}>
        <div style={styles.contactItem}>
          <FaPhone style={ { ...styles.icon, transform: 'scaleX(-1)', color: '#0084ff' }} />
          <p><strong>Phone:</strong> +917740064839</p>
        </div>
        <div style={styles.contactItem}>
          <FaEnvelope style={{ ...styles.icon, color: '#ff0000' }} />
          <p><strong>Email:</strong> info@thestudywave.com</p>
        </div>
        <div style={styles.contactItem}>
          <FaLinkedin style={{ ...styles.icon, color: '#0077b5' }} />
          <p><strong>LinkedIn:</strong> <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer" style={styles.link}>theStudywave.com</a></p>
        </div>
        <div style={styles.contactItem}>
          <FaTwitter style={{ ...styles.icon, color: '#1da1f2' }} />
          <p><strong>Twitter:</strong> <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer" style={styles.link}>@TheStudyWave</a></p>
        </div>
        <div style={styles.contactItem}>
          <FaFacebook style={{ ...styles.icon, color: '#1877f2' }} />
          <p><strong>Facebook:</strong> <a href="https://www.facebook.com/profile.php?id=61556531682580
" target="_blank" rel="noopener noreferrer" style={styles.link}>facebook/theStudyWave</a></p>
        </div>
        <div style={styles.contactItem}>
          <FaInstagram style={{ ...styles.icon, color: '#c13584' }} />
          <p><strong>Instagram:</strong> <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer" style={styles.link}>the_studywave</a></p>
        </div>
      </div>
    </div>

    <footer class="bg-white rounded-lg shadow m-4 dark:bg-gray-800">
    <div class="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
      <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="/" class="hover:underline">The Study Wave™</a>. All Rights Reserved.
    </span>
    <ul class="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
        <li>
          <Link to="/about"> 
            <a href="/about" class="hover:underline me-4 md:me-6">About</a>
            </Link>
        </li>
        <li>
          <Link to="bookclass">
            <a href="/" class="hover:underline me-4 md:me-6">Privacy Policy</a>
            </Link>
        </li>
        <li>
          <Link to="/explorepage">
            <a href="/explorepage" class="hover:underline me-4 md:me-6">Explore</a>
            </Link>
        </li>
        <li>
        <Link to="/contact">
            <a href="/contact" class="hover:underline">Contact</a>
            </Link>
        </li>
    </ul>
    </div>
</footer>
</>
    
  );
}

const styles = {
  container: {
    maxWidth: '1000px',
    margin: '50px auto',
    padding: '20px',
    backgroundImage: 'linear-gradient(to bottom right, #ffffff, #f0f0f0)', // Updated backgroundImage
    borderRadius: '10px',
    boxShadow: '10px 20px 40px rgba(0, 0, 0, 0.2)', // Updated boxShadow
    fontFamily: 'Arial, sans-serif',
    color: '#333',
    lineHeight: '1.6'
  },
  heading: {
    fontSize: '2.5rem',
    marginBottom: '20px',
    fontWeight: 'bold',
    color: '#007bff',
    textAlign: 'center'
  },
  contactInfo: {
    marginTop: '30px',
    fontSize: '1.2rem',
    lineHeight: '2',
  },
  contactItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  icon: {
    marginRight: '10px',
    fontSize: '1.5rem',
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
    transition: 'color 0.3s ease-in-out',
    marginLeft: '5px'
  }
};

export default ContactUs;