import React from 'react';
import commentthumb from '../../src/commentThumb.png';
import tariyal from '../utils/images/Untitled design (7).png';
import message from '../../src/message.png';
import { Link } from 'react-router-dom';
import { SiGoogleclassroom } from "react-icons/si";

export default function Review() {
    return (
        <>
            <div className='flex justify-center items-center my-4 mx-4'>
                <h1 className='font-bold text-2xl md:text-4xl text-cyan-800 mx-2'>Review From Our Students</h1>
                <img className='h-16 w-16 md:h-24 md:w-24' src={commentthumb} alt="" />
            </div>

            <div className='flex justify-between mx-4 my-4'>
                <div className='h-2 w-[40vw] bg-cyan-700'></div>
                <div className='h-2 w-[40vw] bg-cyan-700'></div>
            </div>

            <div className='flex flex-col md:flex-row justify-center items-center mx-4'>
                <div className='flex-1 my-2 mx-2'>
                    <img className="w-20 h-20 md:w-24 md:h-24 mb-3 rounded-full shadow-lg" src="https://images.unsplash.com/photo-1517256673644-36ad11246d21" alt="" />
                    <h5 className="mb-2 text-xl md:text-2xl font-bold tracking-tight text-gray-900">Allison Griffith</h5>
                    <p className="bg-slate-200 p-4 md:p-5 shadow-lg font-normal text-gray-700 rounded-lg">"This platform has truly revolutionized my learning experience! The lessons are engaging, comprehensive, and crafted with such clarity that even the most complex topics become accessible. It's not just an educational website. It is a beacon of knowledge one can only wish for. It is an Amazing platform I have come across with."</p>
                </div>

                <div className='flex-1 my-2 mx-2'>
                    <img className="w-20 h-20 md:w-24 md:h-24 mb-3 rounded-full shadow-lg" src="https://images.unsplash.com/photo-1529068755536-a5ade0dcb4e8" alt="" />
                    <h5 className="mb-2 text-xl md:text-2xl font-bold tracking-tight text-gray-900">David</h5>
                    <p className="bg-slate-200 p-4 md:p-5 shadow-lg font-normal text-gray-700 rounded-lg">"I can't recommend this website enough! It's been a game-changer in my educational journey. The interactive elements and personalized learning paths have helped me grasp concepts more effectively than any textbook ever could. A must-visit for anyone looking to deepen their understanding and enjoy the process along the way."</p>
                </div>

                <div className='flex-1 my-2 mx-2'>
                    <img className="w-20 h-20 md:w-24 md:h-24 mb-3 rounded-full shadow-lg" src="https://images.unsplash.com/photo-1544717305-2782549b5136" alt="" />
                    <h5 className="mb-2 text-xl md:text-2xl font-bold tracking-tight text-gray-900">Elena</h5>
                    <p className="bg-slate-200 p-4 md:p-5 shadow-lg font-normal text-gray-700 rounded-lg">"From the intuitive layout to the high-quality content, this educational website stands out as a top-tier learning platform. The expertly designed courses, accompanied by practical examples, have provided me with invaluable insights and significantly improved my academic performance. Truly a treasure trove for learners."</p>
                </div>
            </div>

            <div className='flex justify-center items-center my-12 mx-4'>
                <div className='bg-cyan-800 h-2 w-16 mx-2'></div>
                <h1 className='text-2xl md:text-4xl font-bold text-cyan-800'>Words From Our Founder</h1>
                <div className='bg-cyan-800 h-2 w-16 mx-2'></div>
            </div>

            <div className='flex flex-col md:flex-row justify-center items-center my-12 mx-4'>
                <div className='mb-4 md:mb-0 w-full md:w-1/3'>
                    <img className='mx-auto' src={tariyal} alt="" />
                </div>
                <div className='md:w-2/3 text-center'>
                    <img className='mx-auto h-20 w-20 md:h-24 md:w-24' src={message} alt="" />
                    <p className='text-gray-600 text-lg md:text-xl font-serif'>"Welcome to our learning community where passion meets purpose. As the founder, I'm thrilled to see your journey unfold. Our mission is to empower you with knowledge, skills, and confidence. Together, we'll explore new horizons and achieve greatness. Thank you for choosing us to be part of your educational adventure."</p>
                </div>
            </div>

            <div className='flex justify-center items-center my-4'>
                <Link to="/bookclass">
                    <button type="button" className="text-white bg-[#1da1f2] hover:bg-[#1da1f2]/90 focus:ring-4 focus:outline-none focus:ring-[#1da1f2]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#1da1f2]/55 mb-2">
                        <SiGoogleclassroom className="text-lg" />
                        <span className='mx-2'>Book A Free Demo Class</span>
                    </button>
                </Link>
            </div>
        </>
    );
}
