import React from 'react';
import testLogo1 from '../utils/images/img1.png'; // Import the logo images
import testLogo2 from '../utils/images/img2.png';
import testLogo3 from '../utils/images/img3.png';
import testLogo4 from '../utils/images/img4.jpg';
import testLogo5 from '../utils/images/img5.svg';
import testLogo6 from '../utils/images/img6.png';
import testLogo7 from '../utils/images/img7.png';


function TestLogos() {
    return (
      <div style={styles.container}>
        <div className='flex'>
          <h2 className="mx-4" style={styles.heading}>Tests We Specialize In</h2>
          <div className='bg-cyan-600 h-1  w-36 my-6'></div>
        </div>
        <div style={styles.logoContainer}>
          <img src={testLogo1} alt="Test 1" style={styles.logo} />
          <img src={testLogo2} alt="Test 2" style={styles.logo} />
          <img src={testLogo3} alt="Test 3" style={styles.logo} />
          <img src={testLogo4} alt="Test 4" style={styles.logo} />
          <img src={testLogo7} alt="Test 7" style={styles.logo} />
          <img src={testLogo6} alt="Test 6" style={styles.logo} />
          <img src={testLogo5} alt="Test 5" style={styles.logo} />
        </div>
      </div>
    );
  }
  
  const styles = {
    container: {
      maxWidth: '98vw',
      margin: '0 auto',
      padding: '10px',
    },
    heading: {
      fontSize: '1.8rem',
      marginBottom: '20px',
    },
    logoContainer: {
      maxWidth: '800px', // Set maximum width for the container
      margin: '0 auto', // Center the container horizontally
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around', // Space logos evenly
      flexWrap: 'wrap', // Allow logos to wrap to the next line if needed
    },
    logo: {
      width: '150px', // Set fixed width for the logos
      height: 'auto',
      margin: '10px',
    },
  };
  
  export default TestLogos;