import React from 'react'
import test1 from '../utils/images/img1.png'
import test2 from '../utils/images/img2.png'
import test3 from '../utils/images/img3.png'
import test4 from '../utils/images/img5.svg'
import test5 from '../utils/images/img7.png'
import test6 from '../utils/images/img6.png'
import test7 from '../utils/images/img4.jpg'
export default function TestLogo() {
  return (
    <>

      <div className='flex justify-center items-center'>
        <div className='h-1 w-[10vw] bg-cyan-500 mx-2'></div>
        <h2 className=' text-lg font-serif text-cyan-600 font-semibold'>"Master the test, master your destiny—we're here to guide you."</h2>
        <div className='h-1 w-[10vw] bg-cyan-500 mx-2'></div>
      </div>



      <div style={styles.container} >
        
        <div style={styles.logoContainer}>
          <img src={test1} alt="Test 1" style={styles.logo} />
          <img src={test2} alt="Test 2" style={styles.logo} />
          <img src={test3} alt="Test 3" style={styles.logo} />
          <img src={test4} alt="Test 4" style={styles.logo} />
          <img src={test7} alt="Test 7" style={styles.logo} />
          <img src={test6} alt="Test 6" style={styles.logo} />
          <img src={test5} alt="Test 5" style={styles.logo} />
        </div>
      </div>
    </>
  )
}

const styles = {
  container: {
    maxWidth: '98vw',
    margin: '0 auto',
    padding: '10px',
  },
  heading: {
    fontSize: '1.8rem',
    marginBottom: '20px',
  },
  logoContainer: {
    maxWidth: '800px', // Set maximum width for the container
    margin: '0 auto', // Center the container horizontally
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around', // Space logos evenly
    flexWrap: 'wrap', // Allow logos to wrap to the next line if needed
  },
  logo: {
    width: '150px', // Set fixed width for the logos
    height: 'auto',
    margin: '10px',
  },
};


